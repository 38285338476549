html {
  scroll-behavior: smooth; }

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit; }
  *:not(i),
  *::after:not(i),
  *::before:not(i) {
    font-family: "Poppins", sans-serif; }

body {
  box-sizing: border-box; }

.cInput {
  display: flex;
  flex-flow: column; }
  .cInput:not(:first-child) {
    margin-top: 0.8rem; }
  .cInput label {
    font-size: 0.8rem; }
  .cInput input,
  .cInput textarea {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-bottom: 2px solid transparent;
    transition: all 1s ease-in-out; }
    .cInput input:focus,
    .cInput textarea:focus {
      border-bottom: 2px solid #1e293b; }

.cRSelect {
  display: flex;
  flex-flow: column; }
  .cRSelect:not(:first-child) {
    margin-top: 0.8rem; }
  .cRSelect label {
    font-size: 0.8rem; }
  .cRSelect input {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-bottom: 2px solid transparent;
    transition: all 1s ease-in-out; }
    .cRSelect input:focus {
      border-bottom: 2px solid #1e293b; }
  .cRSelect .css-1s2u09g-control {
    border: none;
    font-family: "Poppins";
    font-weight: 400;
    border-radius: none;
    margin-top: 0.5rem !important; }

.badgeCus {
  background-color: #f3f3f3;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.cFWButton {
  width: 100%;
  padding: 0.35rem;
  outline: none;
  cursor: pointer; }

.cSelect {
  display: flex;
  flex-flow: column;
  margin-top: 1rem; }
  .cSelect label {
    font-size: 0.8rem; }
  .cSelect select {
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-bottom: 2px solid transparent;
    transition: all 1s ease-in-out; }
    .cSelect select:focus {
      border-bottom: 2px solid #1e293b; }

.cToggle {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  justify-content: space-between; }
  .cToggle label {
    font-size: 0.8rem; }
  .cToggle--switch {
    width: 2rem;
    height: 2rem; }
    .cToggle--switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
+ .MuiSwitch-track {
      background-color: #1e293b; }
    .cToggle--switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
      color: #1e293b; }

.theme-btn {
  background-color: #1e293b;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: .5rem;
  outline: none;
  border: none; }

.round {
  border-radius: 1rem; }

.point {
  cursor: pointer; }

.sidebar__list {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 2rem; }
  .sidebar__list__profileImage {
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%; }
  .sidebar__list__userName {
    margin-top: 1rem;
    margin-bottom: 0; }
  .sidebar__list__email {
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.5); }
  .sidebar__list * {
    color: rgba(255, 255, 255, 0.8); }
  .sidebar__list__dashboard {
    width: 100%;
    margin-top: 2rem;
    padding: 0 1rem; }
    .sidebar__list__dashboard__dashboardLink {
      color: rgba(255, 255, 255, 0.8) !important;
      text-decoration: none !important;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      padding: .9rem 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: .3rem;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important; }

.iconWhite {
  color: white !important; }

.userSidebar {
  display: none !important; }

@media (max-width: 768px) {
  .userSidebarSmall {
    display: block !important; } }

.css-zhkw8w-MuiList-root {
  padding: 2px 0 !important; }

.userNavbar {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 1rem; }
  @media (max-width: 768px) {
    .userNavbar {
      display: none; } }
  .userNavbar__link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-size: 0.8rem;
    margin-right: 1rem;
    padding: 0.3rem 0.5rem;
    border-radius: 3px; }
    .userNavbar__link__logo {
      backface-visibility: hidden;
      width: 6rem;
      height: 4rem;
      object-fit: cover; }
    .userNavbar__link:hover:not(:first-child) {
      background-color: rgba(255, 255, 255, 0.2); }
    .userNavbar__link:nth-child(1) {
      font-size: 1rem;
      text-transform: uppercase;
      padding: 0.4rem 0.5rem;
      border-radius: 3px; }

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 4px 0 !important; }

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding: 4px 8px 4px 8px !important; }

.templateMenu__templateButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.authentication {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .authentication--bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    filter: blur(1rem); }
  @media (max-width: 56.25em) {
    .authentication {
      padding: 2rem; } }
  @media (max-width: 37.5em) {
    .authentication {
      padding: 1rem; } }
  .authentication__box {
    width: 100%;
    background-color: #eee;
    max-width: 60rem;
    height: 100%;
    max-height: 30rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    overflow: hidden; }
    .authentication__box__left {
      width: 50%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      padding: 3rem; }
      @media (max-width: 56.25em) {
        .authentication__box__left {
          width: 100%; } }
      @media (max-width: 56.25em) {
        .authentication__box__left {
          padding: 1.5rem; } }
      .authentication__box__left h1 {
        text-align: center; }
      .authentication__box__left form {
        width: 100%;
        margin-top: 0.5rem; }
        .authentication__box__left form button {
          margin-top: 1.5rem; }
      .authentication__box__left__terms {
        font-size: 0.7rem;
        margin-top: 0.5rem; }
        .authentication__box__left__terms span {
          text-decoration: underline;
          cursor: pointer; }
    .authentication__box__right {
      width: 50%;
      background-color: #1e293b;
      clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0 100%);
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center; }
      .authentication__box__right__logo {
        width: 20rem; }
      .authentication__box__right * {
        color: rgba(255, 255, 255, 0.8); }
      @media (max-width: 56.25em) {
        .authentication__box__right {
          display: none; } }
      .authentication__box__right h1 {
        text-align: center;
        margin-bottom: 0;
        font-size: 1.5rem; }

.table__tableHead {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  color: white !important;
  white-space: nowrap !important; }

.table__tableRow {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  white-space: nowrap !important; }
  .table__tableRow img {
    width: 25px;
    height: 25px;
    object-fit: cover; }

.table__tableRow__subscribeButton {
  text-transform: capitalize !important;
  font-weight: 500 !important;
  border-radius: 1rem !important; }

.tableSearchBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem; }
  .tableSearchBox__searchInput {
    width: 350px;
    outline: none;
    border-radius: 3rem;
    border: 1px solid lightgray;
    padding: .5rem 2.5rem .5rem 1.2rem !important; }
  .tableSearchBox__searchIcon {
    position: absolute;
    margin-right: 1rem;
    font-size: 1.2rem !important;
    color: gray !important; }

.notification {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .notification__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .notification__headerContainer {
        padding: .5rem; } }
    .notification__headerContainer__header {
      display: flex;
      align-items: center; }
      .notification__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .notification__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .notification__headerContainer__submitButton {
      margin-right: .5rem; }
      .notification__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .notification__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem !important; } }
  .notification__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .notification__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .notification__box {
        margin: .5rem; } }

.locationTab {
  background-color: white;
  padding: 2rem;
  border-radius: .5rem;
  box-shadow: 0 0.5rem 1.5rem #d3d3d3; }
  @media (max-width: 560px) {
    .locationTab {
      padding: 1rem; } }
  @media (max-width: 560px) {
    .locationTab h1 {
      font-size: 1rem; } }
  .locationTab__buttons {
    display: flex;
    justify-content: right;
    padding-top: 2rem; }
    .locationTab__buttons__prev {
      background-color: #1e293b !important;
      font-size: .7rem !important;
      margin-right: .3rem !important; }
    .locationTab__buttons__next {
      background-color: #1e293b !important;
      font-size: .7rem !important; }

.css-19kzrtu {
  padding: 0rem !important;
  margin-top: 2rem; }

.endpointsTab {
  background-color: white;
  padding: 2rem;
  border-radius: .5rem;
  box-shadow: 0 0.5rem 1.5rem #d3d3d3; }
  @media (max-width: 560px) {
    .endpointsTab {
      padding: 1rem; } }
  @media (max-width: 560px) {
    .endpointsTab h1 {
      font-size: 1rem; } }
  .endpointsTab__endpointBox {
    background-color: lightgray;
    border-radius: .5rem;
    padding: 1rem;
    box-shadow: 0 0.5rem 1.5rem #d3d3d3;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .endpointsTab__endpointBox {
        display: flex;
        flex-direction: column;
        padding: 1rem; } }
    .endpointsTab__endpointBox span {
      width: 30%;
      font-size: .9rem; }
      @media (max-width: 768px) {
        .endpointsTab__endpointBox span {
          width: 100%;
          display: flex;
          margin-bottom: .5rem; } }
      .endpointsTab__endpointBox span b {
        margin-right: .3rem;
        font-weight: 600; }
  .endpointsTab__buttons {
    display: flex;
    justify-content: right;
    padding-top: 2rem; }
    .endpointsTab__buttons__prev {
      background-color: #1e293b !important;
      font-size: .7rem !important;
      margin-right: .5rem !important; }
    .endpointsTab__buttons__submit {
      background-color: #1e293b !important;
      font-size: .7rem !important; }
      .endpointsTab__buttons__submit:disabled {
        background-color: lightgray !important; }

.endpoint {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .endpoint__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .endpoint__headerContainer {
        padding: .5rem; } }
    .endpoint__headerContainer__header {
      display: flex;
      align-items: center; }
      .endpoint__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .endpoint__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .endpoint__headerContainer__submitButton {
      margin-right: .5rem; }
      .endpoint__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .endpoint__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .endpoint__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .endpoint__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .endpoint__box {
        margin: .5rem; } }

.endpointGroup {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .endpointGroup__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .endpointGroup__headerContainer {
        padding: .5rem; } }
    .endpointGroup__headerContainer__header {
      display: flex;
      align-items: center; }
      .endpointGroup__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .endpointGroup__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .endpointGroup__headerContainer__submitButton {
      margin-right: .5rem; }
      .endpointGroup__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .endpointGroup__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .endpointGroup__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .endpointGroup__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .endpointGroup__box {
        margin: .5rem; } }

.integration {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .integration__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .integration__headerContainer {
        padding: .5rem; } }
    .integration__headerContainer__header {
      display: flex;
      align-items: center; }
      .integration__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .integration__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .integration__headerContainer__submitButton {
      margin-right: .5rem; }
      .integration__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .integration__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .integration__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .integration__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .integration__box {
        margin: .5rem; } }

.system {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .system__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .system__headerContainer {
        padding: .5rem; } }
    .system__headerContainer__header {
      display: flex;
      align-items: center; }
      .system__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .system__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .system__headerContainer__submitButton {
      margin-right: .5rem; }
      .system__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .system__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .system__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .system__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .system__box {
        margin: .5rem; } }

.location {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .location__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .location__headerContainer {
        padding: .5rem; } }
    .location__headerContainer__header {
      display: flex;
      align-items: center; }
      .location__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .location__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .location__headerContainer__submitButton {
      margin-right: .5rem; }
      .location__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .location__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .location__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .location__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .location__box {
        margin: .5rem; } }

.webSignage {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .webSignage__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .webSignage__headerContainer {
        padding: .5rem; } }
    .webSignage__headerContainer__header {
      display: flex;
      align-items: center; }
      .webSignage__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .webSignage__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .webSignage__headerContainer__submitButton {
      margin-right: .5rem; }
      .webSignage__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .webSignage__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .webSignage__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .webSignage__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .webSignage__box {
        margin: .5rem; } }

.companies {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .companies__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .companies__headerContainer {
        padding: .5rem; } }
    .companies__headerContainer__header {
      display: flex;
      align-items: center; }
      .companies__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .companies__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .companies__headerContainer__submitButton {
      margin-right: .5rem; }
      .companies__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .companies__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .companies__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .companies__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .companies__box {
        margin: .5rem; } }

.sso {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .sso__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .sso__headerContainer {
        padding: .5rem; } }
    .sso__headerContainer__header {
      display: flex;
      align-items: center; }
      .sso__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .sso__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .sso__headerContainer__submitButton {
      margin-right: .5rem; }
      .sso__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .sso__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .sso__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .sso__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .sso__box {
        margin: .5rem; } }

.logs {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .logs__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .logs__headerContainer {
        padding: .5rem; } }
    .logs__headerContainer__header {
      display: flex;
      align-items: center; }
      .logs__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .logs__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .logs__headerContainer__submitButton {
      margin-right: .5rem; }
      .logs__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .logs__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .logs__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .logs__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .logs__box {
        margin: .5rem; } }

.alerts {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .alerts__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .alerts__headerContainer {
        padding: .5rem; } }
    .alerts__headerContainer__header {
      display: flex;
      align-items: center; }
      .alerts__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .alerts__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .alerts__headerContainer__submitButton {
      margin-right: .5rem; }
      .alerts__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .alerts__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .alerts__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .alerts__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .alerts__box {
        margin: .5rem; } }

.notificationHistory {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .notificationHistory__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .notificationHistory__headerContainer {
        padding: .5rem; } }
    .notificationHistory__headerContainer__header {
      display: flex;
      align-items: center; }
      .notificationHistory__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .notificationHistory__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .notificationHistory__headerContainer__submitButton {
      margin-right: .5rem; }
      .notificationHistory__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .notificationHistory__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .notificationHistory__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .notificationHistory__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .notificationHistory__box {
        margin: .5rem; } }

.dashboard {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .dashboard__header {
    background-color: #F1F5F9;
    padding: 2rem;
    transition: padding .5s; }
    @media (max-width: 550px) {
      .dashboard__header {
        padding: .5rem; } }
    .dashboard__header__heading {
      font-size: 1.5rem; }
      @media (max-width: 560px) {
        .dashboard__header__heading {
          font-size: 1.2rem; } }
  .dashboard__box__notifications {
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    transition: margin, flex-wrap .5s; }
    @media (max-width: 768px) {
      .dashboard__box__notifications {
        margin: 2rem 1rem; } }
    @media (max-width: 560px) {
      .dashboard__box__notifications {
        margin: 1rem .5rem;
        display: flex;
        justify-content: space-between; } }
    .dashboard__box__notifications__notificationBox {
      margin: 0 1rem 2rem 1rem;
      box-shadow: 0 0.5rem 1.5rem #D3D3D3;
      background-color: white;
      width: 9rem;
      height: 7rem;
      display: flex;
      flex-flow: column;
      justify-content: center;
      text-align: center;
      border-radius: 1rem;
      font-size: .9rem;
      color: white;
      padding: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      transition: text-shadow, transform .5s; }
      .dashboard__box__notifications__notificationBox:hover {
        text-shadow: 1px 1px 8px gray;
        transform: scale(1.05); }
      @media (max-width: 560px) {
        .dashboard__box__notifications__notificationBox {
          width: 45%;
          margin: 0 .5rem 1rem .5rem; } }
      @media (max-width: 370px) {
        .dashboard__box__notifications__notificationBox {
          width: 100%;
          margin: 0 .5rem 1rem .5rem; } }
      .dashboard__box__notifications__notificationBox:nth-last-child(1) {
        color: #1e293b;
        background-color: lightgray; }
      .dashboard__box__notifications__notificationBox div {
        margin-top: -1rem;
        font-weight: bold;
        color: #1e293b;
        text-decoration: none; }
      .dashboard__box__notifications__notificationBox__name {
        margin-bottom: .5rem; }
      .dashboard__box__notifications__notificationBox__subject {
        font-weight: normal; }
      .dashboard__box__notifications__notificationBox__addIcon {
        margin-bottom: .5rem;
        font-weight: bold; }
      .dashboard__box__notifications__notificationBox__menuIcon {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        right: -1.2rem;
        top: -.5rem; }
        .dashboard__box__notifications__notificationBox__menuIcon:hover {
          opacity: 1 !important; }

.template {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .template__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .template__headerContainer {
        padding: .5rem; } }
    .template__headerContainer__header {
      display: flex;
      align-items: center; }
      .template__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .template__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .template__headerContainer__submitButton {
      margin-right: .5rem; }
      .template__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .template__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .template__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 3rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .template__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .template__box {
        margin: 1rem; } }

.templateForm {
  width: 100%;
  max-width: 50rem; }
  .templateForm__form {
    padding: 2rem 2rem;
    border-radius: 5px;
    border: 1px solid lightgray; }
    @media (max-width: 560px) {
      .templateForm__form {
        padding: 1rem 1rem; } }
    .templateForm__form__textFields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .templateForm__form__textFields__textField {
        transition: color .5s;
        width: 48%; }
        .templateForm__form__textFields__textField span {
          font-size: .8rem; }
        .templateForm__form__textFields__textField:hover {
          color: #007FFF; }
    .templateForm__form__buttons {
      display: flex;
      justify-content: flex-end; }
      .templateForm__form__buttons__submitButton {
        background-color: #1e293b !important;
        color: rgba(255, 255, 255, 0.8) !important;
        margin-top: 2rem !important;
        text-transform: capitalize !important;
        padding: 5px 12px !important;
        font-size: .9rem !important;
        border-radius: 10px !important; }
    .templateForm__form__fileUpload span {
      font-size: .8rem; }
    .templateForm__form__fileUpload:hover {
      color: #007FFF; }
    .templateForm__form__voiceRecord {
      margin-top: 2.5rem;
      border: 1px solid lightgray;
      border-radius: 3px;
      padding: .6rem; }
      .templateForm__form__voiceRecord span {
        font-size: .8rem; }
      .templateForm__form__voiceRecord:hover {
        color: #007FFF;
        border: 1px solid black; }
      .templateForm__form__voiceRecord:focus {
        border: 1px solid #007FFF; }
      .templateForm__form__voiceRecord__controls {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .templateForm__form__voiceRecord__controls audio {
          height: 30px;
          padding: 0;
          filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
          transition: width 0.5s;
          width: 48%; }
          @media (max-width: 560px) {
            .templateForm__form__voiceRecord__controls audio {
              width: 90%; } }
        .templateForm__form__voiceRecord__controls__container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: .3em;
          background-color: #1e293b;
          border-radius: 1.2em;
          width: 120px;
          color: rgba(255, 255, 255, 0.8); }
          .templateForm__form__voiceRecord__controls__container__circle {
            background-color: red;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            animation: ease pulse 2s infinite;
            margin-right: 0.25em; }

@keyframes pulse {
  0% {
    background-color: red; }
  50% {
    background-color: #f06c6c; }
  100% {
    background-color: red; } }
          .templateForm__form__voiceRecord__controls__container__circleTwo {
            background-color: white;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            margin-right: 0.25em; }
          .templateForm__form__voiceRecord__controls__container__text {
            color: rgba(255, 255, 255, 0.8);
            font-size: .8rem; }
      .templateForm__form__voiceRecord__fileName {
        padding: 0 !important;
        position: absolute;
        margin-top: -2rem !important;
        margin-left: -.55rem; }

.templateDeleteModal {
  outline: none !important;
  border: none !important;
  border-radius: .5rem;
  box-shadow: 0 0.5rem 1.5rem #d3d3d3; }
  .templateDeleteModal__confirm {
    font-size: 1.2rem; }
  .templateDeleteModal__buttons {
    margin-top: 1.2rem;
    display: flex;
    justify-content: flex-end; }
    .templateDeleteModal__buttons__deleteButton {
      background-color: #1e293b !important;
      text-transform: capitalize !important;
      margin-right: .5rem !important; }
    .templateDeleteModal__buttons__cancelButton {
      background-color: gray !important;
      text-transform: capitalize !important; }

.viewTemplate {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .viewTemplate__headerContainer {
    display: flex;
    justify-content: space-between;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .viewTemplate__headerContainer {
        padding: .5rem; } }
    .viewTemplate__headerContainer__header {
      display: flex;
      align-items: center; }
      .viewTemplate__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .viewTemplate__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .viewTemplate__headerContainer__submitButton {
      margin-right: .5rem; }
      .viewTemplate__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .viewTemplate__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .viewTemplate__box {
    margin: 3rem 2rem;
    display: flex;
    justify-content: center;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .viewTemplate__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .viewTemplate__box {
        margin: 1rem; } }
    .viewTemplate__box__view {
      padding: 4rem 2rem;
      border-radius: 5px !important;
      box-shadow: 0 0.5rem 1.5rem #d3d3d3;
      transition: transform .5s;
      color: #1e293b;
      max-width: 80rem;
      width: 100%;
      transition: width .5s; }
      .viewTemplate__box__view__name {
        font-weight: normal;
        margin-bottom: 1rem;
        font-size: 2.5rem;
        margin-bottom: 0;
        transition: font-size .5s; }
        @media (max-width: 550px) {
          .viewTemplate__box__view__name {
            font-size: 2rem; } }
      .viewTemplate__box__view__body {
        margin-top: 1rem;
        margin-bottom: .2rem;
        text-align: justify;
        width: 80%; }
        @media (max-width: 1000px) {
          .viewTemplate__box__view__body {
            width: 100%; } }
      .viewTemplate__box__view__subject {
        margin-bottom: .5rem;
        border-bottom: 1px solid gray;
        padding-bottom: 1rem;
        margin-top: 1rem; }
      .viewTemplate__box__view audio {
        filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%); }
      .viewTemplate__box__view__voiceButton {
        margin-top: 2rem !important;
        padding: 0 !important;
        transition: width .5s;
        height: 30px;
        width: 25%; }
        @media (max-width: 1150px) {
          .viewTemplate__box__view__voiceButton {
            width: 30%; } }
        @media (max-width: 1000px) {
          .viewTemplate__box__view__voiceButton {
            width: 40%; } }
        @media (max-width: 768px) {
          .viewTemplate__box__view__voiceButton {
            width: 60%; } }
        @media (max-width: 560px) {
          .viewTemplate__box__view__voiceButton {
            width: 100%; } }

.profile {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .profile__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .profile__headerContainer {
        padding: .5rem; } }
    .profile__headerContainer__header {
      display: flex;
      align-items: center; }
      .profile__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .profile__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .profile__headerContainer__submitButton {
      margin-right: .5rem; }
      .profile__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .profile__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .profile__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .profile__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .profile__box {
        margin: .5rem; } }

.users {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .users__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .users__headerContainer {
        padding: .5rem; } }
    .users__headerContainer__header {
      display: flex;
      align-items: center; }
      .users__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .users__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .users__headerContainer__submitButton {
      margin-right: .5rem; }
      .users__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .users__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .users__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .users__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .users__box {
        margin: .5rem; } }

.adminPackages {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .adminPackages__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .adminPackages__headerContainer {
        padding: .5rem; } }
    .adminPackages__headerContainer__header {
      display: flex;
      align-items: center; }
      .adminPackages__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .adminPackages__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .adminPackages__headerContainer__submitButton {
      margin-right: .5rem; }
      .adminPackages__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .adminPackages__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .adminPackages__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .adminPackages__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .adminPackages__box {
        margin: .5rem; } }

.icMobile {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .icMobile__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .icMobile__headerContainer {
        padding: .5rem; } }
    .icMobile__headerContainer__header {
      display: flex;
      align-items: center; }
      .icMobile__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .icMobile__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .icMobile__headerContainer__submitButton {
      margin-right: .5rem; }
      .icMobile__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .icMobile__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .icMobile__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .icMobile__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .icMobile__box {
        margin: .5rem; } }

.home {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .home__header {
    width: 100%;
    max-width: 70rem;
    height: 50vh;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0 0.5rem 1.5rem #d3d3d3; }
    @media (max-width: 768px) {
      .home__header {
        height: 100vh;
        border-radius: unset; } }
    .home__header__icon {
      display: flex;
      justify-content: flex-end;
      padding: 1rem; }
    .home__header__heading {
      box-sizing: border-box;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }

.packages {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .packages__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .packages__headerContainer {
        padding: .5rem; } }
    .packages__headerContainer__header {
      display: flex;
      align-items: center; }
      .packages__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .packages__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .packages__headerContainer__submitButton {
      margin-right: .5rem; }
      .packages__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .packages__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .packages__box {
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .packages__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .packages__box {
        margin: .5rem; } }

.packagesCards__header__heading {
  text-align: start;
  font-size: 1.5rem; }
  @media (max-width: 560px) {
    .packagesCards__header__heading {
      font-size: 1.2rem; } }

.packagesCards__cards {
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }
  .packagesCards__cards__card {
    box-sizing: border-box;
    box-shadow: 0 0.5rem 1.5rem #d3d3d3;
    padding: 0 1.5rem 1.5rem 1.5rem;
    border-radius: 1rem;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: border .5s;
    margin-bottom: 4rem;
    border: 4px solid #365996;
    transition: transform .5s ease-in-out;
    background-color: #F1F5F9; }
    .packagesCards__cards__card:hover {
      transform: scale(1.05); }
    .packagesCards__cards__card__packageName {
      margin-top: -.1rem;
      font-size: 1.5rem;
      background-color: #365996;
      padding: .1rem 1rem;
      text-align: center;
      border-radius: 0 0 1rem 1rem;
      color: white; }
    .packagesCards__cards__card__priceBox {
      margin-top: 2rem;
      font-size: 1.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center; }
      .packagesCards__cards__card__priceBox__discount {
        font-weight: 500;
        font-size: 1rem;
        color: green; }
      .packagesCards__cards__card__priceBox__price {
        font-size: 2.5rem; }
      .packagesCards__cards__card__priceBox__billPeriod {
        font-size: .9rem;
        margin-top: -.8rem;
        text-align: center; }
    .packagesCards__cards__card__duration {
      margin-top: 2rem;
      font-size: .9rem;
      text-align: center; }
    .packagesCards__cards__card Button {
      margin-top: 2rem;
      text-transform: capitalize;
      background-color: #365996 !important;
      padding: .15rem 1rem;
      font-size: .9rem !important; }
      .packagesCards__cards__card Button:disabled {
        color: white !important;
        background-color: lightgray !important; }

.profile {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .profile__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .profile__headerContainer {
        padding: .5rem; } }
    .profile__headerContainer__header {
      display: flex;
      align-items: center; }
      .profile__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .profile__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .profile__headerContainer__submitButton {
      margin-right: .5rem; }
      .profile__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .profile__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .profile__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .profile__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .profile__box {
        margin: .5rem; } }

.userProfile {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .userProfile__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .userProfile__headerContainer {
        padding: .5rem; } }
    .userProfile__headerContainer__header {
      display: flex;
      align-items: center; }
      .userProfile__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .userProfile__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .userProfile__headerContainer__submitButton {
      margin-right: .5rem; }
      .userProfile__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .userProfile__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .userProfile__box {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem; }
    @media (max-width: 560px) {
      .userProfile__box {
        margin: 0 1rem; } }
    .userProfile__box__profileAndSubscriptions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 1rem;
      transition: margin .5s; }
      @media (max-width: 1000px) {
        .userProfile__box__profileAndSubscriptions {
          display: flex;
          flex-direction: column; } }

.subscriptionsDetails {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: .5rem;
  background-color: #F1F5F9;
  width: 450px; }
  @media (max-width: 1000px) {
    .subscriptionsDetails {
      width: 100% !important; } }
  .subscriptionsDetails__header__heading {
    font-size: 1.5rem; }
    @media (max-width: 560px) {
      .subscriptionsDetails__header__heading {
        font-size: 1.2rem; } }
  .subscriptionsDetails__header__subscriptionId {
    display: flex;
    font-size: .9rem;
    font-weight: 600; }
    .subscriptionsDetails__header__subscriptionId p {
      margin-left: .3rem;
      color: green; }
  .subscriptionsDetails__currentPlan {
    margin-top: 1rem;
    display: flex;
    flex-direction: column; }
    .subscriptionsDetails__currentPlan__name {
      font-weight: 500;
      font-size: .9rem; }
    .subscriptionsDetails__currentPlan__duration {
      font-weight: 500;
      font-size: .9rem; }
  .subscriptionsDetails__subscriptionDate {
    margin-top: 1rem;
    font-weight: 500;
    font-size: .9rem;
    color: green; }
  .subscriptionsDetails__expiration {
    font-weight: 500;
    font-size: .9rem;
    color: red; }
  .subscriptionsDetails__viewPlanButton {
    margin-top: 2rem !important;
    text-transform: capitalize !important; }

.profileCard {
  background-color: #F1F5F9;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: .5rem;
  padding: 1.5rem;
  margin-right: 2rem;
  flex: 1;
  background-image: url("./assets/Profile.jpg");
  background-size: cover;
  color: white; }
  @media (max-width: 1000px) {
    .profileCard {
      margin-right: 0; } }
  .profileCard__header__heading {
    font-size: 1.5rem; }
    @media (max-width: 560px) {
      .profileCard__header__heading {
        font-size: 1.2rem; } }
  .profileCard__avatar {
    margin-top: 2rem;
    height: 50px !important;
    width: 50px !important;
    margin-left: 1rem; }
  .profileCard__name {
    margin-left: 1.5rem;
    margin-top: 2rem; }
  .profileCard__email {
    font-size: .9rem;
    font-weight: 500;
    margin-left: 1.5rem; }

.companyProfile {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: .5rem;
  background-color: #F1F5F9; }
  .companyProfile__header__heading {
    font-size: 1.5rem; }
    @media (max-width: 560px) {
      .companyProfile__header__heading {
        font-size: 1.2rem; } }
  .companyProfile__companyName {
    margin: 1rem; }
    .companyProfile__companyName__name {
      display: flex;
      align-items: center; }
      .companyProfile__companyName__name img {
        height: 30px;
        width: 30px;
        object-fit: cover;
        margin-right: .5rem; }
    .companyProfile__companyName p {
      font-size: .8rem;
      font-weight: 500;
      margin-left: .2rem;
      margin-top: .3rem; }
    .companyProfile__companyName__usersDetails {
      display: flex; }
      .companyProfile__companyName__usersDetails p {
        margin-right: 1rem; }
        .companyProfile__companyName__usersDetails p:hover {
          text-decoration: underline; }
  .companyProfile__details {
    margin: 1rem; }
    .companyProfile__details__locations {
      margin-bottom: .5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: .9rem;
      font-weight: 500;
      border-radius: .5rem;
      background-color: white;
      padding-top: 1rem;
      padding-bottom: .5rem;
      box-shadow: 0 0.5rem 1.5rem #d3d3d3; }
      .companyProfile__details__locations__created {
        display: flex;
        margin-bottom: .5rem;
        width: 33%;
        min-width: 250px;
        padding-left: 1rem; }
        @media (max-width: 850px) {
          .companyProfile__details__locations__created {
            padding-left: 1rem;
            border-left: none; } }
        .companyProfile__details__locations__created p {
          margin-left: .3rem; }
      .companyProfile__details__locations__available {
        display: flex;
        color: green;
        margin-bottom: .5rem;
        width: 33%;
        min-width: 150px;
        border-left: 2px solid gray;
        padding-left: .5rem; }
        @media (max-width: 850px) {
          .companyProfile__details__locations__available {
            padding-left: 1rem;
            border-left: none; } }
        .companyProfile__details__locations__available p {
          margin-left: .3rem; }
      .companyProfile__details__locations__limit {
        display: flex;
        color: red;
        margin-bottom: .5rem;
        width: 33%;
        min-width: 150px;
        border-left: 2px solid gray;
        padding-left: .5rem; }
        @media (max-width: 850px) {
          .companyProfile__details__locations__limit {
            padding-left: 1rem;
            border-left: none; } }
        .companyProfile__details__locations__limit p {
          margin-left: .3rem; }

.paymentScreen {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .paymentScreen__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .paymentScreen__headerContainer {
        padding: .5rem; } }
    .paymentScreen__headerContainer__header {
      display: flex;
      align-items: center; }
      .paymentScreen__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .paymentScreen__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .paymentScreen__headerContainer__submitButton {
      margin-right: .5rem; }
      .paymentScreen__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .paymentScreen__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .paymentScreen__box {
    margin: 2rem;
    transition: margin .5s;
    box-shadow: 0 0.5rem 1.5rem #d3d3d3;
    border-radius: 1rem;
    padding: 2rem; }
    @media (max-width: 768px) {
      .paymentScreen__box {
        margin: 1rem;
        padding: 1rem; } }
    @media (max-width: 560px) {
      .paymentScreen__box {
        margin: .5rem; } }

.paymentSuccess {
  width: 100%; }
  .paymentSuccess h2 {
    text-align: center;
    margin-top: 100px;
    color: #37f837; }

.checkoutForm__button {
  display: flex;
  justify-content: flex-end; }
  .checkoutForm__button__payButton {
    margin-top: 1rem !important;
    background-color: black !important;
    text-transform: capitalize !important; }
    @media (max-width: 500px) {
      .checkoutForm__button__payButton {
        width: 100%; } }

.distributionList {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .distributionList__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .distributionList__headerContainer {
        padding: .5rem; } }
    .distributionList__headerContainer__header {
      display: flex;
      align-items: center; }
      .distributionList__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .distributionList__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .distributionList__headerContainer__submitButton {
      margin-right: .5rem; }
      .distributionList__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .distributionList__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .distributionList__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .distributionList__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .distributionList__box {
        margin: .5rem; } }

.webPages {
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh;
  width: 100%; }
  .webPages__headerContainer {
    display: flex;
    background-color: #F1F5F9;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    transition: padding .5s; }
    @media (max-width: 560px) {
      .webPages__headerContainer {
        padding: .5rem; } }
    .webPages__headerContainer__header {
      display: flex;
      align-items: center; }
      .webPages__headerContainer__header__heading {
        font-size: 1.5rem; }
        @media (max-width: 560px) {
          .webPages__headerContainer__header__heading {
            font-size: 1.2rem; } }
    .webPages__headerContainer__submitButton {
      margin-right: .5rem; }
      .webPages__headerContainer__submitButton Button {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #f7f7f7 !important;
        text-transform: capitalize;
        border-radius: .5rem;
        padding-left: 1rem;
        padding-right: 1rem; }
        @media (max-width: 560px) {
          .webPages__headerContainer__submitButton Button {
            font-size: .8rem;
            padding: 0.2rem .8rem; } }
  .webPages__box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: margin .5s; }
    @media (max-width: 768px) {
      .webPages__box {
        margin: 1rem; } }
    @media (max-width: 560px) {
      .webPages__box {
        margin: .5rem; } }
